export const useApiFetch = (path, options) => {

  // return $fetch(path, {
  //   baseURL: 'https://nekidaem.ru',
  //   credentials: 'include',
  //   ...options,
  // })
  const {public: runtimeConfig } = useRuntimeConfig()

  return $fetch(path, {
    baseURL: runtimeConfig.baseURL,
    credentials: 'include',
    ...options,
  })
}
